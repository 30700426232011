html {
  font-family: Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #faf45b;
  color: #333;
}
@media screen and (min-width: 1440px) {
  html {
    font-size: 16px;
  }
}
@media screen and (max-width: 1440px) {
  html {
    font-size: 14px;
  }
}
@media screen and (max-width: 1024px) {
  html {
    font-size: 13px;
  }
}
html * {
  box-sizing: border-box;
}
html a {
  color: #333;
  text-decoration: none;
}
html a:hover {
  text-decoration: underline;
}
body {
  padding-bottom: 12.5rem;
}
header {
  position: relative;
  height: 6.875rem;
  background-color: #000;
  padding: 1.4375rem;
}
@media screen and (max-width: 768px) {
  header {
    height: 8.875rem;
  }
}
header .logo-wrapper {
  display: flex;
  align-items: flex-end;
}
header .logo-wrapper .logo {
  display: block;
  width: 15.5rem;
}
header .logo-wrapper .logo img {
  display: block;
}
header .logo-wrapper .site-title {
  margin-left: 0.625rem;
  font-family: 'Arial Black', sans-serif;
}
header .logo-wrapper .site-title h1 {
  color: #be1818;
  font-size: 0.875rem;
  margin: 0;
}
header .logo-wrapper .site-title .slogan {
  color: #fff;
  font-size: 0.75rem;
  margin: 0;
}
header .dimension-switch {
  position: absolute;
  right: 1.875rem;
  bottom: 0;
}
header .dimension-switch a {
  float: left;
  height: 2.5rem;
  line-height: 2.5rem;
  border-radius: 0.3125rem 0.3125rem 0 0;
  color: #faf45b;
  font-size: 1.25rem;
  padding: 0 0.9375rem;
  text-decoration: none;
}
header .dimension-switch a.active {
  background-color: #faf45b;
  color: #000;
  font-family: 'Arial Black', sans-serif;
}
.years,
.breaks,
.tournaments {
  display: flex;
  flex-wrap: wrap;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s;
}
.years.shown,
.breaks.shown,
.tournaments.shown {
  visibility: visible;
  opacity: 1;
}
.breaks,
.tournaments {
  display: none;
}
.baler {
  width: 26.25rem;
  margin: 3.125rem 0 0 3.125rem;
}
.baler h3 {
  font-family: 'Arial Black', sans-serif;
  font-size: 2.25rem;
  margin: 0 0 1.25rem;
}
.baler h3 span {
  font-size: 1.25rem;
  margin-left: 0.5rem;
}
.baler h4 {
  font-family: 'Arial Black', sans-serif;
  font-size: 1.375rem;
  margin: 1.25rem 0 1rem;
}
.baler h4 span {
  display: block;
  font-size: 1rem;
}
.baler ul {
  margin: 0;
  padding: 0;
}
.baler ul li {
  position: relative;
  list-style: none;
  line-height: 1.5;
}
.baler ul li:before {
  display: none;
  content: '';
  position: absolute;
  left: -1rem;
  top: 0.4rem;
  width: 0.4rem;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-color: transparent transparent transparent #be1818;
  border-style: solid;
}
.baler ul li[v]:before {
  display: block;
}
.baler ul li .no,
.baler ul li strong {
  font-weight: bold;
  font-family: 'Arial Black', sans-serif;
}
.baler ul li .no {
  cursor: pointer;
}
